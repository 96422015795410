import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  id: '',
  hide_my_agenda: false,
  hide_speakers: false,
  hide_notifications: false,
  hide_sponsors: false,
  show_session_seats: false,
  my_agenda_name: '',
  agenda_name: '',
  sponsors_name: '',
  status: requestStatus.UNDONE,
  error: ''
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_SETTINGS_REQUEST:
    case types.UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        error: '',
      };

    case types.GET_SETTINGS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: '',
      };

    case types.GET_SETTINGS_SUCCESS:
    case types.CREATE_SETTINGS_SUCCESS:
    case types.UPDATE_SETTINGS_SUCCESS:
      if (!action.payload) {
        return {
          ...state,
          status: requestStatus.DONE,
        };
      }

      const {
        hide_my_agenda,
        hide_speakers,
        hide_notifications,
        hide_sponsors,
        show_session_seats,
        my_agenda_name,
        agenda_name,
        sponsors_name,
        id
      } = action.payload;

      return {
        ...state,
        id,
        hide_my_agenda,
        hide_speakers,
        hide_notifications,
        hide_sponsors,
        show_session_seats,
        my_agenda_name,
        agenda_name,
        sponsors_name,
        status: requestStatus.DONE,
      };

    case types.GET_SETTINGS_ERROR:
    case types.CREATE_SETTINGS_ERROR:
    case types.UPDATE_SETTINGS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
