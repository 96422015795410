import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  list: [],
  status: requestStatus.UNDONE,
  error: ''
};

const sponsors = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SPONSORS_REQUEST:
    case types.CREATE_SPONSORS_REQUEST:
    case types.FIND_ALL_SPONSORS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.UPDATE_SPONSORS_ERROR:
    case types.CREATE_SPONSORS_ERROR:
    case types.FIND_ALL_SPONSORS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.CREATE_SPONSORS_SUCCESS: {
      const payload = action.payload;
      const newSponsors = Array.isArray(payload) ? payload : [payload];

      return {
        ...state,
        status: requestStatus.DONE,
        list: state.list.concat(newSponsors),
        error: ''
      };
    }

    case types.UPDATE_SPONSORS_SUCCESS:
      return {
        ...state,
        list: state.list.map(room => {
          return room.id === action.payload.id ? action.payload : room;
        }),
        status: requestStatus.DONE,
      };

    case types.FIND_ALL_SPONSORS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.DELETE_SPONSOR:
      return {
        ...state,
        list: state.list.filter(sponsor => sponsor.id !== action.payload)
      };

    default:
      return state;
  }
};

export default sponsors;
