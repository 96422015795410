export const DEFAULT_CATEGORY = {
  label: "sponsors:category:standard",
  value: 1
};

export const SPONSOR_CATEGORIES = [
  DEFAULT_CATEGORY,
  {
    label: "sponsors:category:bronze",
    value: 2
  },
  {
    label: "sponsors:category:silver",
    value: 3
  },
  {
    label: "sponsors:category:gold",
    value: 4
  },
  // {
  //   label: "sponsors:category:platinum",
  //   value: 5
  // }
];

export const getSponsorCategoryLabel = (value) => {
  const category = SPONSOR_CATEGORIES.find((c) => c.value === value);

  return category ? category.label : DEFAULT_CATEGORY.label;
};
