import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import Elements from './components/utils/stripe/localized-elements';
import { exitSuperAdmin } from './services/AuthService';
import { getItem, setItem } from './services/StorageService';
import AuthCallback from './components/auth/callback';
import AuthLogin from './components/auth/login';
import SuperAdminLogin from './components/auth/superadmin';
import Wizard from './containers/wizard';
import ConfsHome from './components/confs/home';
import { ViewWithSidebar } from './components/sidebar';
import SuspenseLoader from './components/utils/suspense-loader';
import AuthWidget from './components/auth/widget';
import ConfPlansModal from './components/notifications/conf-plans-modal';
import ConfHome from './components/confs/home/conf-home';
import ConfProgram from './components/confs/program/sessions-list';
import ConfTheme from './components/confs/theme/conf-theme';
import ConfRooms from './components/confs/rooms/room-list';
import ConfSponsors from './components/confs/sponsors/sponsor-list';
import ConfUpgrade from './components/confs/upgrade';
import ConfNotifications from './components/confs/notifications/conf-notifications';
import Billing from './components/billing/billing';
import Consent from './components/auth/consent';
import { SUPERADMIN_KEY } from './constants';

// https://gist.github.com/briancavalier/842626
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }
          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const ConfLinks = lazy(() => retry(() => import('./components/confs/links/links-list')));
const ConfSpeakers = lazy(() => retry(() => import('./components/confs/speakers/speaker-list')));
const ConfAccess = lazy(() => retry(() => import('./components/confs/access/access')));
const ConfAdvancedSettings = lazy(() => retry(() => import('./components/confs/advanced-settings')));

const AppRoute = ({ component: Component, sidebar = true, ...rest }) => {
  const { auth } = useMappedState(state => ({ auth: state.auth }));
  return (
    <Route
      {...rest}
      render={props => {
        setItem('last_uri', window.location.pathname);
        if (auth.user !== null && auth.user.authenticated === true) {
          return sidebar ? (
            <ViewWithSidebar>
              <Suspense fallback={<SuspenseLoader />}>
                <Component {...props} />
              </Suspense>
            </ViewWithSidebar>
          ) : (
            <Suspense fallback={<SuspenseLoader />}>
              <Component {...props} />
            </Suspense>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/login'
            }}
          />
        );
      }}
    />
  );
};

const SuperAdmin = styled.div`
  height: 50px;
  width: 100%;
  background-color: #e74c3c;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Router = () => {
  let superAdmin;

  try {
    superAdmin = getItem(SUPERADMIN_KEY);
  } catch (e) {
    console.error(e);
  }

  const { t } = useTranslation();

  return (
    <BrowserRouter>
      {superAdmin && (
        <SuperAdmin>
          {t('auth:superadmin', { name: superAdmin.name || superAdmin.userId })}{' '}
          <div
            style={{ marginLeft: 5, cursor: 'pointer' }}
            title={t('auth:exit_superadmin')}
            onClick={exitSuperAdmin}
          >
            <CrossCircleIcon primaryColor="#c0392b" />{' '}
          </div>
        </SuperAdmin>
      )}
      <Route path="/wizard" exact component={() => <Wizard />} />
      <AppRoute path="/" exact component={() => <ConfsHome />} />
      <AppRoute path="/conf/:id" exact component={() => <ConfHome />} />
      <AppRoute path="/conf/:id/theme" exact component={() => <ConfTheme />} />
      <AppRoute
        path="/conf/:id/program"
        exact
        isAuthenticated={true}
        component={() => <ConfProgram />}
      />
      <AppRoute
        path="/conf/:id/speakers"
        exact
        isAuthenticated={true}
        component={() => <ConfSpeakers />}
      />
      <AppRoute
        path="/conf/:id/rooms"
        exact
        isAuthenticated={true}
        component={() => <ConfRooms />}
      />
      <AppRoute
        path="/conf/:id/links"
        exact
        isAuthenticated={true}
        component={() => <ConfLinks />}
      />
      <AppRoute
        path="/conf/:id/notifications"
        exact
        isAuthenticated={true}
        component={() => <ConfNotifications />}
      />
      <AppRoute
        path="/conf/:id/sponsors"
        exact
        isAuthenticated={true}
        component={() => <ConfSponsors />}
      />
      <AppRoute
        path="/conf/:id/upgrade"
        exact
        isAuthenticated={true}
        component={() => (
          <Elements>
            <ConfUpgrade />
          </Elements>
        )}
      />
      <AppRoute
        path="/conf/:id/access"
        exact
        isAuthenticated={true}
        component={() => <ConfAccess />}
      />
      <AppRoute
        path="/conf/:id/advanced-settings"
        exact
        isAuthenticated={true}
        component={() => <ConfAdvancedSettings />}
      />
      <AppRoute
        path="/settings/billing"
        exact
        isAuthenticated={true}
        component={() => (
          <Elements>
            <Billing />
          </Elements>
        )}
      />
      <AppRoute path="/consent" exact component={() => <Consent />} sidebar={false} />
      <Route path="/callback" exact render={() => <AuthCallback />} />
      <Route path="/login" exact render={() => <AuthLogin />} />
      <Route path="/superadmin/:userId" exact render={() => <SuperAdminLogin />} />
      <AuthWidget />
      <ConfPlansModal />
    </BrowserRouter>
  );
};

export default Router;
