const sponsors = {
  UPDATE_SPONSORS_REQUEST: 'UPDATE_SPONSORS_REQUEST',
  UPDATE_SPONSORS_SUCCESS: 'UPDATE_SPONSORS_SUCCESS',
  UPDATE_SPONSORS_ERROR: 'UPDATE_SPONSORS_ERROR',

  CREATE_SPONSORS_REQUEST: 'CREATE_SPONSORS_REQUEST',
  CREATE_SPONSORS_SUCCESS: 'CREATE_SPONSORS_SUCCESS',
  CREATE_SPONSORS_ERROR: 'CREATE_SPONSORS_ERROR',

  FIND_ALL_SPONSORS_REQUEST: 'FIND_ALL_SPONSORS_REQUEST',
  FIND_ALL_SPONSORS_SUCCESS: 'FIND_ALL_SPONSORS_SUCCESS',
  FIND_ALL_SPONSORS_ERROR: 'FIND_ALL_SPONSORS_ERROR',

  DELETE_SPONSOR: 'DELETE_SPONSOR'
};

export default sponsors;
