import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose, isEmpty } from 'lodash/fp';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import AddIcon from '@atlaskit/icon/glyph/add';
import { useTranslation } from 'react-i18next';
import { findRooms, createRoom, updateRoom, deleteRoom } from '../../../store/actions/rooms';
import { areRoomsLoaded } from '../../../store/selectors/rooms';
import ConfSelected from '../../../containers/conf-selected';
import CreateModal from '../../utils/entities/entity-form';
import Page from '../../layout/page';
import RoomForm from './room-create';
import EmptyRooms from '../../layout/empty';
import EntityTile from '../../utils/entities/entity-tile';
import FontAwesomeIcon from '../../utils/icons/font-awesome-icon';
import PlanInfo from '../../layout/plan-info';
import useMask from '../../../hooks/use-mask';
import { getMaskData } from '../../utils/get-mask-data';
import { MASK } from '../../../constants';
import { isLastProgressEntity } from '../../../store/selectors/progress';
import XlsxExport from '../../utils/xlsx-export';
import XlsxImport from '../../utils/xlsx-imports';

const RoomList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  @media (min-width: 640px) {
    justify-content: flex-start;
  }
`;

const ConfRooms = ({ selectedConf }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState({});
  const { showMask, resetMask } = useMask();

  const { list, roomsLoaded, isLastInProgress } = useMappedState(state => ({
    list: state.rooms.list,
    roomsLoaded: areRoomsLoaded(state),
    isLastInProgress: isLastProgressEntity(state)
  }));

  useEffect(() => {
    if (roomsLoaded && list.length < 1) {
      const maskData = getMaskData(MASK.CREATE_ROOM, t);
      showMask(maskData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (selectedConf) {
      dispatch(findRooms(selectedConf.id));
    }
  }, [dispatch, selectedConf]);

  const submitForm = (data, isNew) => {
    const payload = {
      ...(!isNew && { id: data.id }),
      name: data.name.trim(),
      conf_id: selectedConf.id
    };

    dispatch(isNew ? createRoom(payload) : updateRoom(payload))
      .then(() => {
        setOpen(false);

        if (list.length < 1) {
          const text = isLastInProgress ? MASK.EVENT_READY : MASK.NEXT_STEP;
          const maskData = getMaskData(text, t);
          showMask(maskData);
        }
      })
      .catch(() => {
        // do nothing since we've already displayed the flag
        // or the plan upgrade modal
      });
  };

  const addModal = (
    <>
      <CreateModal
        DisplayForm={RoomForm}
        label={<AddIcon size="small" label="add icon" />}
        open={open}
        onSubmit={submitForm}
        onOpen={() => {
          resetMask();
          setSelectedRoom({});
          setOpen(true);
        }}
        onCancel={() => setOpen(false)}
        entityName="room"
        entity={selectedRoom}
      >
        <div style={{
          display: "flex",
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10
        }}>
          <XlsxExport
            eventName={selectedConf.name}
            eventId={selectedConf.id}
            entityName="rooms"
            disabled={isEmpty(list)}
          />
          <XlsxImport
            eventName={selectedConf.name}
            eventId={selectedConf.id}
            entityName="rooms"
          />
        </div>
      </CreateModal>
      <PlanInfo
        name={'rooms'}
        planId={selectedConf.conf_limits.current_plan}
        limit={selectedConf.conf_limits.max_rooms}
        used={list.length}
      />
    </>
  );

  return (
    <Page
      title={t('rooms:title')}
      addChildren={addModal}
      showProgress={true}
      titleTooltip={t('rooms:titleTooltip')}
    >
      {isEmpty(list) ? (
        roomsLoaded && <EmptyRooms entityName="rooms" />
      ) : (
        <RoomList>
          {list.map(room => {
            return (
              <EntityTile
                key={room.id}
                title={room.name}
                picture={<FontAwesomeIcon icon='door-open' size={'2x'} color={'var(--blue-2)'}/>}
                deleteModalConfig={{
                  modalHeading: t('rooms:actions:confirm_remove:header'),
                  modalContent: <>
                    <div>{t('rooms:actions:confirm_remove:warning', { name: room.name })}</div>
                    {!isEmpty(room.session || []) && (
                      <>
                        <div>{t('rooms:actions:confirm_remove:consequences', { name: room.name })}</div>
                        <ul>
                          {(room.session || []).map(session => (
                            <li key={session.id}>{session.name}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                }}
                onDelete={() => dispatch(deleteRoom(room, list.length))}
                onEdit={() => {
                  setSelectedRoom(room);
                  setOpen(true);
                }}
              />
            );
          })}
        </RoomList>
      )}
    </Page>
  );
};

export default compose(
  withRouter,
  ConfSelected
)(ConfRooms);
