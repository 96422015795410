import { areIntervalsOverlapping, format, max, min, parse, startOfDay } from 'date-fns/esm';
import { isDate } from '../components/utils/dates';
import { isEmpty, isEqual } from 'lodash';
import { filter, flatMap, flow, map, uniqBy } from 'lodash/fp';
import { getZonedISOString } from './DatesService';

const isSessionsOverlapped = (a, b) => {
  // As in session edit date to can be in 'HH:mm' format
  const from = new Date(a.from);
  const to = isDate(a.to) ? new Date(a.to) : parse(a.to, 'HH:mm', from);

  const bFrom = new Date(b.from);
  const bTo = new Date(b.to);

  // to avoid an error in the next areIntervalsOverlapping when start > end
  if (from >= to || bFrom >= bTo) {
    return false;
  }

  // Check sessions overlap by dates
  if (
    !areIntervalsOverlapping(
      { start: from, end: to },
      { start: bFrom, end: bTo }
    )
  ) {
    return false;
  }

  const isOverlappingByRoom = !isEmpty(a.room_id) && a.room_id === b.room_id;

  const isOverlappingBySpeakers = (a.speakers || []).some(speaker =>
    b.speakers.some(s => s.id === speaker.id)
  );

  // Check sessions overlap by room or speakers
  return isOverlappingByRoom || isOverlappingBySpeakers;
};

export const getOverlappingSessionsForSession = (sessions, session) => {
  return sessions.filter(sess => {
    if (sess.id === session.id || !isDate(session.from) || isEmpty(session.to)) {
      return false;
    }
    const dateFrom = new Date(session.from);
    const date = isDate(session.to) ? new Date(session.to) : parse(session.to, 'HH:mm', dateFrom);
    if (dateFrom >= date) {
      return false;
    }
    return isSessionsOverlapped(session, sess);
  });
};

export const getOverlappingSessions = sessions => {
  // e.g. [[session1, session2], [session2, session3, session4]]
  return sessions
    .reduce((acc, sess) => {
      const overlappingSessions = sessions.filter(session => isSessionsOverlapped(session, sess));

      // add the conflict only if we didn't already find it
      if (!acc.some(sessions => isEqual(sessions, overlappingSessions))) {
        return [...acc, overlappingSessions];
      }
      return acc;
    }, [])
    .filter(sessions => sessions.length > 1);
};

export const getOverlappingSessionsByRoom = (overlappingSessions, roomId) => {
  return filter({ room: { id: roomId } })(overlappingSessions);
};

export const getOverlappingSessionsBySpeakers = (overlappingSessions, speakerIds) => {
  return overlappingSessions.filter(session =>
    session.speakers.some(speaker => speakerIds.includes(speaker.id))
  );
};

export const getOverlappingSpeakers = overlappingSessions => {
  return flow(
    flatMap('speakers'),
    uniqBy('id'),
    map('name')
  )(overlappingSessions);
};

export const getOverlappingDate = overlappingSessions => {
  const from = min(overlappingSessions.map(session => new Date(session.from)));
  const to = max(
    overlappingSessions.map(session =>
      isDate(session.to) ? new Date(session.to) : parse(session.to, 'HH:mm', new Date(session.from))
    )
  );
  return { from, to };
};

export const getOverlappingDateString = (overlappingSessions, timeFormat) => {
  const { from, to } = getOverlappingDate(overlappingSessions);
  return `${format(from, timeFormat)} -> ${format(to, timeFormat)}`;
};

export const isDayOutOfRange = (day, from, to) => {
  return startOfDay(day) < startOfDay(new Date(from)) || startOfDay(day) > startOfDay(new Date(to));
};

export const isSessionsOutOfRange = (from, to, sessions) => {
  return sessions.some(session => {
    return isDayOutOfRange(new Date(session.from), from, to);
  });
};

export const getOutOfEventDays = (days, from, to) => {
  return days.filter(day => isDayOutOfRange(day, from, to));
};

export const getZonedSessionData = (session) => {
  const timezone = session.conf && session.conf.timezone;

  return {
    ...session,
    from: getZonedISOString(session.from, timezone),
    to: getZonedISOString(session.to, timezone)
  };
};
export const getZonedNotificationData = (notification, timezone) => {
  const zonedSentAt = notification.sent_at
    ? getZonedISOString(notification.sent_at, timezone)
    : notification.sent_at;

  const zonedDate = getZonedISOString(notification.date, timezone);

  return {
    ...notification,
    date: zonedDate,
    sent_at: zonedSentAt
  };
};

export const getSpeakersData = (session) => {
  const { speakers } = session;

  if (!speakers || !speakers.length) {
    return {
      ...session,
      speakers: [],
    };
  }

  if (speakers[0].speaker) {
    return {
      ...session,
      speakers: speakers.map(item => item.speaker),
    };
  }

  return { ...session };
};

