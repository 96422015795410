import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import { isValidUrl } from '../../../services/ValidationService';
import { ErrorMessage } from '@atlaskit/form';
import { useTranslation } from 'react-i18next';
import FontAwesomeIcon from '../../utils/icons/font-awesome-icon';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import Button from '@atlaskit/button';
import DelIcon from '@atlaskit/icon/glyph/cross';
import AddIcon from '@atlaskit/icon/glyph/add';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import Select from '@atlaskit/select';

const SocialFormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CustomSelect = styled(Select)`
  width: 100%;
`;
const SocialInputWrapper = styled.div`
  margin: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 5px;
  }
`;

const SelectOption = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const SocialSelectText = styled.div`
  color: #586171;
  ${SelectOption}:hover>& {
    color: #7a869a;
  }
`;
const SocialIcon = styled(FontAwesomeIcon)`
  padding: 4px;
  margin-right: 10px;
  ${SelectOption}:hover>& {
    color: #7a869a;
  }
`;
const SocialSelectedOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 10px 10px;
`;

const iconProps = {
  color: '#586171',
  size: '1x'
};

export const defaultNetwork = { name: 'other', Icon: <SocialIcon {...iconProps} icon={faUsers} /> };
const socialNetworks = [
  { name: 'twitter', Icon: <SocialIcon {...iconProps} icon={faTwitter} /> },
  { name: 'facebook', Icon: <SocialIcon {...iconProps} icon={faFacebook} /> },
  { name: 'linkedin', Icon: <SocialIcon {...iconProps} icon={faLinkedin} /> },
  defaultNetwork
];

const getIconFromName = name => socialNetworks.find(network => network.name === name);
export const checkIsValid = text => isValidUrl(text) === true && text !== '' && text !== undefined;

export function SocialInput({ onChange, onDelete, network }) {
  const [name, setName] = useState(network.name);
  const [value, setValue] = useState(network.value);
  const [invalid, setInvalid] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (invalid === false && value !== network.value) {
      onChange({ name, value });
    }
  }, [invalid, name, network.value, onChange, value]);

  const onSelectChange = ({ value }) => {
    setName(value.name);
  };

  return (
    <>
      <SocialInputWrapper>
        <CustomSelect
          isSearchable={false}
          classNamePrefix="react-select-social"
          className="single-select"
          onChange={onSelectChange}
          defaultValue={{ label: t(`speakers:socials:${name}`), value: name }}
          menuPortalTarget={document.body}
          styles={{
            container: base => ({ ...base, fontFamily: 'inherit' }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
          }}
          components={{
            SingleValue: props => {
              const Icon = getIconFromName(name).Icon;
              return (
                <SocialSelectedOption>
                  {Icon}
                  <SocialSelectText>{props.children}</SocialSelectText>
                </SocialSelectedOption>
              );
            },
            Option: props => {
              const { Icon } = props.data.value;
              return (
                <SelectOption {...props.innerProps}>
                  {Icon} <SocialSelectText>{props.children}</SocialSelectText>
                </SelectOption>
              );
            }
          }}
          options={socialNetworks.map(network => ({
            label: t(`speakers:socials:${network.name}`),
            value: network
          }))}
        />
        <Textfield
          name="social-value"
          isInvalid={invalid}
          onBlur={$evt => setInvalid(!checkIsValid($evt.target.value))}
          placeholder={t('speakers:form:link')}
          defaultValue={value}
          onChange={$evt => setValue($evt.target.value)}
        />
        <Button appearance="danger" onClick={onDelete}>
          <DelIcon size="small" />
        </Button>
      </SocialInputWrapper>
      {invalid && <ErrorMessage>{t('speakers:form:errors:link')}</ErrorMessage>}
    </>
  );
}

// eslint-disable-next-line
export default function({ entity = 'speakers', onChange, networks = [] }) {
  const { t } = useTranslation();
  const [values, setValues] = useState(networks);
  useEffect(() => {
    onChange(values);
  }, [onChange, values]);

  const onNetworkChange = index => data => {
    const newNetworks = values.map((network, i) => (i === index ? data : network));
    setValues(newNetworks);
  };

  const onAdd = () => {
    setValues([...values, defaultNetwork]);
  };
  const onDelete = index => () => {
    const newNetworks = values.filter((_, i) => i !== index);
    setValues(newNetworks);
  };
  return (
    <SocialFormWrapper>
      {values.map((network, index) => (
        <SocialInput
          key={`network#${index}`}
          onChange={onNetworkChange(index)}
          onDelete={onDelete(index)}
          network={network}
        />
      ))}
      {values.length < 4 && (
        <AddButtonWrapper>
          <Button
            appearance="primary"
            spacing="compact"
            iconBefore={<AddIcon size="small" />}
            onClick={onAdd}
          >
            {t(`${entity}:form:add_social`)}
          </Button>
        </AddButtonWrapper>
      )}
    </SocialFormWrapper>
  );
}
