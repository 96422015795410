class DataLayer {
  constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  emitSuggestMiniAppEvent(name, email) {
    window.dataLayer.push({
      event: 'suggest_mini_app',
      suggestedMiniAppName: name,
      email
    });
  }

  emitSearchMiniAppEvent(name, email) {
    window.dataLayer.push({
      event: 'search_mini_apps',
      searchedMiniAppName: name,
      email
    });
  }

  emitImportEventData(event, entity, email) {
    window.dataLayer.push({
      event: 'import_event_data',
      eventName: event,
      importedEventEntity: entity,
      email
    });
  }

  emitExportEventData(event, entity, email) {
    window.dataLayer.push({
      event: 'export_event_data',
      eventName: event,
      exportedEventEntity: entity,
      email
    });
  }

  emitWizardCompletedEvent() {
    window.dataLayer.push({
      event: 'bo_wizard_completed',
    });
  }
}

const dataLayer = new DataLayer();

export default dataLayer;
