import React from 'react';
import { Field } from '@atlaskit/form';
import { useTranslation } from 'react-i18next';
import SocialForm, { checkIsValid } from '../speakers/social-form';
import { SizedTextfield, SizedTextarea } from '../../utils/sized-textfield';
import { ImageUploader } from '../../upload/image-uploader';
import Select from '@atlaskit/select';
import { DEFAULT_CATEGORY, SPONSOR_CATEGORIES } from './sponsor-categories';
import Tip from '../../utils/tip';

const validateLink = values => {
  const isValid = values.every(({ value }) => checkIsValid(value));
  return isValid ? undefined : 'INVALID';
};

const SponsorForm = ({ sponsor }) => {
  const { t } = useTranslation();
  const links = sponsor.links || [];
  const defaultCategory = SPONSOR_CATEGORIES.find(c => c.value === sponsor.category || DEFAULT_CATEGORY.value);
  const defaultLogo = sponsor.logo || process.env.REACT_APP_LOGO_SMALL_URL;

  return (
    <>
      <Field
        name="logo"
        defaultValue={defaultLogo}
      >
        {({ fieldProps }) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ImageUploader
              {...fieldProps}
              imageStyle={{
                height: 90,
                width: 160,
                objectFit: 'contain',
                border: '1px solid #dcdcdc',
              }}
              editLabelStyle={{right: '-30px'}}
            />
          </div>
        )}
      </Field>
      <div style={{ marginBottom: '10px', textAlign: 'center' }}>
        <Tip>{t('common:form:image_ratio')}</Tip>
      </div>
      <Field
        label={t('speakers:form.name')}
        isRequired={true}
        name="name"
        defaultValue={sponsor.name || ''}
      >
        {({ fieldProps }) => <SizedTextfield {...fieldProps} maxLength={50} autoFocus />}
      </Field>
      <Field
        name="category"
        label={t('sponsors:form:category')}
        isRequired
        defaultValue={defaultCategory}
      >
        {({ fieldProps: { id, ...rest }}) => (
          <Select
            {...rest}
            inputId={id}
            options={SPONSOR_CATEGORIES}
            formatOptionLabel={(item) => t(item.label)}
            menuPortalTarget={document.body}
            styles={{
              container: base => ({ ...base, fontFamily: 'inherit' }),
              menuPortal: base => ({ ...base, zIndex: 9999 })
            }}
          />
        )}
      </Field>
      <Field label={t('speakers:form.desc')} name="desc" defaultValue={sponsor.desc || ''}>
        {({ fieldProps }) => <SizedTextarea {...fieldProps} maxLength={1000} />}
      </Field>
      <Field
        validate={validateLink}
        label={t('sponsors:links')}
        name="links"
        defaultValue={links}
      >
        {({ fieldProps }) => {
          return <SocialForm entity="sponsors" networks={links} {...fieldProps} />;
        }}
      </Field>
    </>
  );
};

export default SponsorForm;
