import types from '../types';
import handleError from '../utils/errors';
import SponsorsService from '../../services/SponsorsService';

export const updateSponsor = sponsor => dispatch => {
  dispatch({
    type: types.UPDATE_SPONSORS_REQUEST
  });

  return SponsorsService.update(sponsor)
    .then((updatedSponsor) => {
      dispatch({
        type: types.UPDATE_SPONSORS_SUCCESS,
        payload: updatedSponsor
      });
    })
    .catch(err => handleError(types.UPDATE_SPONSORS_ERROR, err, dispatch));
};

export const createSponsor = sponsor => dispatch => {
  dispatch({
    type: types.CREATE_SPONSORS_REQUEST
  });

  return SponsorsService.create(sponsor)
    .then(createdSponsor => {
      return dispatch({
        type: types.CREATE_SPONSORS_SUCCESS,
        payload: createdSponsor
      });
    })
    .catch(err => handleError(types.CREATE_SPONSORS_ERROR, err, dispatch));
};

export const findSponsors = confId => dispatch => {
  dispatch({
    type: types.FIND_ALL_SPONSORS_REQUEST
  });
  return SponsorsService.findAllOfConf(confId)
    .then(sponsors => {
      return dispatch({
        type: types.FIND_ALL_SPONSORS_SUCCESS,
        payload: sponsors
      });
    })
    .catch(err => handleError(types.FIND_ALL_SPONSORS_ERROR, err, dispatch));
};

export const deleteSponsor = (sponsorId) => dispatch => {
  return SponsorsService.delete(sponsorId)
    .then(() =>
      dispatch({
        type: types.DELETE_SPONSOR,
        payload: sponsorId
      })
    );
};
