import { createSelector } from 'reselect';
import { requestStatus } from '../request-status';

const selectSponsorsStatus = state => state.sponsors.status;
const selectSponsors = state => state.sponsors.list;

export const isSponsorsLoaded = createSelector(
  selectSponsorsStatus,
  status => status === requestStatus.DONE
);

export const selectSortedSponsors = createSelector(
  selectSponsors,
  list => list.sort((a, b) => b.category - a.category),
);
