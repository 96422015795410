import React from 'react';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import Deletable from '../deletable';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';

const TileImgContainer = styled.div`
  flex: 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 10px 15px;
  text-align: center;
  
  > :nth-child(2) {
    display: none;
  }
`;

const TileEditButton = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  margin: 0 15px;
  transform: translateY(-50%);
`;

const Tile = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  background-color: var(--gray-4);
  margin: 0 0 15px 0;
  padding: 7px;
  position: relative;
  
  @media (min-width: 640px) {
    margin-right: 15px;
    
    &:last-child {
      margin-right: 0;
    }
  }
    
  &:hover ${TileImgContainer} > :nth-child(1) {
    display: none;
  }
  &:hover ${TileImgContainer} > :nth-child(2) {
    display: block;
  }
  &:hover ${TileEditButton} {
    display: block;
  }
`;

const TileImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`;

const TileDetails = styled.div`
  overflow: hidden;
  flex: 1;
  font-size: 20px;
  margin: 10px 30px 10px 5px;
`;

const TileDesc = styled.div`
  color: var(--gray-2);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TileCorner = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: var(--gray-2);
  font-size: 12px;
  font-style: italic;
`;

// eslint-disable-next-line
export default ({ picture, title, desc, cornerBlock, deleteModalConfig, onDelete, onEdit }) => {
  const { modalHeading, modalContent } = deleteModalConfig;
  return (
    <Tile>
      {cornerBlock && <TileCorner>{cornerBlock}</TileCorner>}
      <TileImgContainer>
        {typeof picture === 'string' ? <TileImg alt="tile" src={picture} /> : picture}
        <Deletable
          onDelete={() => onDelete()}
          modalHeading={modalHeading}
          modalContent={modalContent}
          noButton
        >
          <Button appearance="danger" iconBefore={<TrashIcon label="trash-icon" />} />
        </Deletable>
      </TileImgContainer>
      <TileDetails>
        <div>{title}</div>
        <TileDesc title={desc}>{desc}</TileDesc>
      </TileDetails>
      <TileEditButton>
        <Button
          appearance="subtle"
          iconBefore={<EditFilledIcon label="edit-icon" />}
          onClick={() => onEdit()}
        />
      </TileEditButton>
    </Tile>
  );
};
