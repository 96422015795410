import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'lodash/fp';
import { isEmpty } from 'lodash';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import EmptySpeakers from '../../layout/empty';
import ConfSelected from '../../../containers/conf-selected';
import Page from '../../layout/page';
import CreateFormModal from '../../utils/entities/entity-form';
import EntityTile from '../../utils/entities/entity-tile';
import Tooltip from 'react-tooltip';
import { createSponsor, deleteSponsor, findSponsors, updateSponsor } from '../../../store/actions/sponsors';
import { isSponsorsLoaded, selectSortedSponsors } from '../../../store/selectors/sponsors';
import SponsorForm from './sponsor-form';
import { getSponsorCategoryLabel } from './sponsor-categories';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  @media (min-width: 640px) {
    justify-content: flex-start;
  }
`;

const SponsorLogoImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const ConfSponsors = ({ selectedConf }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedSponsor, setSelectedSponsor] = useState({});

  const { list, selectedId, sponsorsLoaded } = useMappedState(state => ({
    list: selectSortedSponsors(state),
    selectedId: state.confs.selectedId,
    sponsorsLoaded: isSponsorsLoaded(state),
  }));

  useEffect(() => {
    if (selectedConf) {
      dispatch(findSponsors(selectedConf.id));
    }
  }, [dispatch, selectedConf]);

  const submitForm = (data, isNew) => {
    const payload = {
      ...(!isNew && { id: data.id }),
      name: data.name.trim(),
      desc: data.desc.trim(),
      category: data.category.value,
      logo: data.logo,
      conf_id: selectedId,
      links: data.links
    };

    dispatch(isNew ? createSponsor(payload) : updateSponsor(payload))
      .then(() => setOpen(false));
  };

  const onRemove = id => {
    dispatch(deleteSponsor(id, list.length));
  };

  const formModal = (
    <>
      <Tooltip />
      <CreateFormModal
        DisplayForm={SponsorForm}
        open={open}
        onSubmit={submitForm}
        onOpen={() => {
          setSelectedSponsor({});
          setOpen(true);
        }}
        onCancel={() => setOpen(false)}
        entityName='sponsor'
        entity={selectedSponsor}
      />
    </>
  );

  return (
    <Page
      title={t('sponsors:title')}
      addChildren={formModal}
      showProgress
      titleTooltip={t('sponsors:titleTooltip')}
    >
      {isEmpty(list) ? (
        sponsorsLoaded && <EmptySpeakers entityName="sponsors" />
      ) : (
        <Wrapper>
          {list.map(sponsor => {
            const categoryLabel = getSponsorCategoryLabel(sponsor.category);

            return (
              <EntityTile
                key={sponsor.id}
                title={sponsor.name}
                desc={t(categoryLabel)}
                picture={<SponsorLogoImg src={sponsor.logo} />}
                deleteModalConfig={{
                  modalHeading: t('sponsors:actions:confirm_remove:header'),
                  modalContent: t('sponsors:actions:confirm_remove:warning', { name: sponsor.name })
                }}
                onDelete={() => onRemove(sponsor.id)}
                onEdit={() => {
                  setSelectedSponsor(sponsor);
                  setOpen(true);
                }}
              />
            );
          })}
        </Wrapper>
      )}
    </Page>
  );
};

export default compose(
  withRouter,
  ConfSelected
)(ConfSponsors);
