import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { map } from 'lodash';
import { compose, isEmpty, isEqual, cloneDeep, isNil } from 'lodash/fp';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import Error from '@atlaskit/icon/glyph/error';
import EditIcon from '@atlaskit/icon/glyph/edit';
import AddIcon from '@atlaskit/icon/glyph/add';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import { colors } from '@atlaskit/theme';
import AvatarGroup from '@atlaskit/avatar-group';
import EmptySessions from '../../layout/empty';
import { format, getUtcFromZonedTime, getZonedISOString } from '../../../services/DatesService';
import { addFlag } from '../../../store/actions/flags';
import {
  getSessionsOfDayGroupedByHalfHour,
  getSessionsDays,
  getSessionsRooms,
  areSessionsLoaded,
  getSelectedDay,
  getSessionsSpeakers,
  sessionsHaveFilters
} from '../../../store/selectors/sessions';
import {
  findSessions,
  selectDay,
  selectRooms,
  selectSpeakers,
  deleteSession,
  createSession,
  updateSession,
  resetFilters
} from '../../../store/actions/sessions';
import { createRoom } from '../../../store/actions/rooms';
import Page from '../../layout/page';
import Deletable from '../../utils/deletable';
import CreateModal from '../../utils/entities/entity-form';
import ConfSelected from '../../../containers/conf-selected';
import SessionForm from './session-form';
import DaysSelect from './days-select';
import MultiSelect from './multi-select';
import SpeakersService from '../../../services/SpeakersService';
import { parse, startOfDay, isEqual as dateisEqual } from 'date-fns/esm';
import { findSpeakers } from '../../../store/actions/speakers';
import ConflictsPopover from './conflicts-popover';
import LinksService from '../../../services/LinksService';
import { endOfDayIsPast } from '../../utils/dates';
import Tooltip from 'react-tooltip';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { getMaskClass, getMaskData } from '../../utils/get-mask-data';
import { MASK } from '../../../constants';
import useMask from '../../../hooks/use-mask';
import { isLastProgressEntity } from '../../../store/selectors/progress';
import { getTimeFormat } from '../../../store/selectors/account';
import ProgramInfo from './program-info';
import XlsxExport from '../../utils/xlsx-export';
import XlsxImport from '../../utils/xlsx-imports';

const RightBlock = styled.div`
  display: flex;
  z-index: 0;
  align-items: center;
  > :nth-last-child(1) {
    display: none;
  }
`;

const DeletableWrapper = styled.div`
  margin-right: 10px;
  display: none;
`;

const SessionContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: var(--gray-4);
  position: relative;
  border-radius: 10px;
  &:hover ${RightBlock} > :nth-last-child(2) {
    display: none !important;
  }
  &:hover ${RightBlock} > :nth-last-child(1) {
    display: flex;
  }
  &:hover ${DeletableWrapper} {
    display: block;
  }
`;
const HoverActions = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  & > * {
    margin-left: 5px;
  }
`;

const Room = styled.div`
  font-size: 18px;
  font-style: italic;
  color: var(--gray-3);
  margin-left: 5px;
`;

const ConfProgram = ({ selectedConf }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showMask, resetMask } = useMask();

  const {
    sessions,
    sessionsByHalfHour,
    isFiltered,
    selectedDay,
    selectedRooms,
    selectedSpeakers,
    sessionsDays,
    sessionsLoaded,
    sessionsRooms,
    sessionsSpeakers,
    isLastInProgress,
    timeFormat
  } = useMappedState(state => ({
    sessions: state.sessions.list,
    sessionsByHalfHour: getSessionsOfDayGroupedByHalfHour(state),
    sessionsDays: getSessionsDays(state),
    sessionsRooms: getSessionsRooms(state),
    sessionsLoaded: areSessionsLoaded(state),
    isFiltered: sessionsHaveFilters(state),
    sessionsSpeakers: getSessionsSpeakers(state),
    selectedDay: getSelectedDay(state),
    selectedRooms: state.sessions.selectedRooms,
    selectedSpeakers: state.sessions.selectedSpeakers,
    isLastInProgress: isLastProgressEntity(state),
    timeFormat: getTimeFormat(state)
  }));
  const [selectedSession, setSelectedSession] = useState(null);
  const [isPopoverOpen, setPopoverOpen] = useState(null);
  const onDaySelected = day => dispatch(selectDay(day));
  const onRoomsSelected = rooms => dispatch(selectRooms(rooms));
  const onSpeakersSelected = speakers => dispatch(selectSpeakers(speakers));
  const isSelectedConfPast = endOfDayIsPast(selectedConf.to);

  useEffect(() => {
    if (sessionsLoaded && sessions.length < 1) {
      const maskData = getMaskData(MASK.CREATE_SESSION, t);
      showMask(maskData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  useEffect(() => {
    if (selectedConf) {
      dispatch(findSessions(selectedConf.id));
      dispatch(findSpeakers(selectedConf.id));
    }
  }, [dispatch, selectedConf]);

  const addSession = () => {
    resetMask();
    setSelectedSession({});
  }

  const onRemove = id => {
    dispatch(deleteSession(id, sessions.length));
  };

  const onSubmit = (payload, isNew) => {
    let { tags = [], room, from, to, speakers, apps, ...session } = payload;

    // tags = [...(session.tags || []), ...tags].map(tag => tag.label);
    // for hiding tags we always save them as []
    tags = [];

    if (selectedSession.id) {
      session.id = selectedSession.id;
    }

    (room && room.__isNew__
      ? dispatch(createRoom({ name: room.value, conf_id: selectedConf.id })).then(
          req => req.payload.id
        )
      : Promise.resolve((room || {}).value)
    )
      .then(roomId => {
        const fromDate = new Date(from);
        const toDate = parse(to, 'HH:mm', fromDate);

        return dispatch(
          (isNew ? createSession : updateSession)({
            ...session,
            tags,
            from: getUtcFromZonedTime(fromDate, selectedConf.timezone),
            to: getUtcFromZonedTime(toDate, selectedConf.timezone),
            room_id: roomId || null,
            conf_id: selectedConf.id
          })
        );
      })
      .then(req => {
        // Avoid requests if there's no changes
        if (selectedSession.id) {
          const sessionSpeakerIds = (
            sessions.find(session => session.id === selectedSession.id) || {}
          ).speakers.map(speaker => speaker.id);

          if (isEqual(sessionSpeakerIds, speakers.map(f => f.value))) {
            return isNew ? req.payload : null;
          }
        }

        return (selectedSession.id
          ? SpeakersService.deleteSessionSpeakers(selectedSession.id)
          : Promise.resolve()
        )
          .then(() => {
            if (!isEmpty(speakers)) {
              return SpeakersService.createSessionSpeakers(
                speakers.map(speaker => ({
                  speaker_id: speaker.value,
                  session_id: isNew ? req.payload.id : selectedSession.id
                }))
              );
            }
          })
          .then(() => (isNew ? req.payload : null));
      })
      .then(session => {
        // Avoid requests if there are no apps changes
        if (selectedSession.id && isEqual(selectedSession.apps, apps)) {
          return isNew ? session : null;
        }

        return (selectedSession.id
            ? LinksService.deleteSessionLinks(selectedSession.id)
            : Promise.resolve()
        )
          .then(() => {
            if (!isEmpty(apps)) {
              return LinksService.createSessionLinks(
                apps.map(app => ({
                  session_id: isNew ? session.id : selectedSession.id,
                  name: app.name,
                  icon: app.icon,
                  type: app.type,
                  content: app.content,
                  app: app.app,
                }))
              );
            }
          })
          .then(() => (isNew ? session : null));
      })
      .then(session => {
        setSelectedSession(null);
        // As the return data is in a bad format, we want to refresh session list and select session day
        return dispatch(findSessions(selectedConf.id)).then(({ payload: sessionsList }) => {
          // to show next step  after first session creating
          if (isNew && sessionsList.length === 1) {
            const text = isLastInProgress ? MASK.EVENT_READY : MASK.NEXT_STEP;
            const maskData = getMaskData(text, t);
            showMask(maskData);
          }

          if (isNew) {
            const sessionFrom = getZonedISOString(session.from, selectedConf.timezone);
            // Check that the new session begin date isn't the same as the current one
            const day = startOfDay(new Date(sessionFrom));
            if (!dateisEqual(day, selectedDay)) {
              return dispatch(selectDay(day));
            }
          }
          return Promise.resolve();
        });
      })
      .catch(() => {
        dispatch(
          addFlag({
            description: '',
            appearance: 'error',
            icon: <Error label="Error icon" primaryColor={colors.R300} />,
            title: 'sessions:actions:create_error'
          })
        );
      });
  };

  return (
    <>
      <CreateModal
        DisplayForm={SessionForm}
        open={selectedSession !== null}
        onSubmit={onSubmit}
        onCancel={() => setSelectedSession(null)}
        entityName={'session'}
        entity={selectedSession}
        selectedConf={selectedConf}
      />
      <Page
        title={t('sessions:title')}
        titleTooltip={t('sessions:titleTooltip')}
        afterTitle={
          <ConflictsPopover
            isOpen={isPopoverOpen === 'GLOBAL_POPOVER'}
            setOpen={open => setPopoverOpen(open ? 'GLOBAL_POPOVER' : null)}
            sessions={sessions}
            selectedConf={selectedConf}
            sessionsDays={sessionsDays}
            timeFormat={timeFormat}
            render={(onClick) => {
              return (
                <div style={{ cursor: 'pointer' }} onClick={onClick}>
                  <WarningIcon
                    label="warn-icon"
                    primaryColor="var(--orange-3)"
                    size="large"
                  />
                </div>
              )
            }}
          />
        }
        addChildren={
          <>
            <div style={{
              display: "flex",
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10
            }}>
              <XlsxExport
                eventName={selectedConf.name}
                eventId={selectedConf.id}
                entityName="sessions"
                disabled={isEmpty(sessions)}
              />
              <XlsxImport
                eventName={selectedConf.name}
                eventId={selectedConf.id}
                entityName="sessions"
                disabled={isSelectedConfPast}
              />
              <div
                {...(isSelectedConfPast
                  ? { 'data-tip': t('common:errors.past_event') }
                  : {})
                }
              >
                <Button
                  appearance="primary"
                  className={getMaskClass(MASK.CREATE_SESSION)}
                  iconBefore={<AddIcon size="small" label="add icon" />}
                  onClick={addSession}
                  isDisabled={isSelectedConfPast}
                >
                  {t('sessions:create:new')}
                </Button>
              </div>
            </div>
            <ProgramInfo
              planId={selectedConf.conf_limits.current_plan}
              confId={selectedConf.id}
            />
          </>
        }
        showProgress={true}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ flex: 3 }}>
            <DaysSelect days={sessionsDays} value={selectedDay} onSelect={onDaySelected} />
          </div>
          <MultiSelect
            data={sessionsRooms}
            value={selectedRooms}
            label={t('rooms:title')}
            onSelect={onRoomsSelected}
          />
          <MultiSelect
            data={sessionsSpeakers}
            value={selectedSpeakers}
            label={t('speakers:title')}
            onSelect={onSpeakersSelected}
          />
          {isFiltered && (
            <div style={{ marginLeft: 15 }}>
              <Button
                appearance="primary"
                iconBefore={<EditorCloseIcon />}
                onClick={() => dispatch(resetFilters())}
              >
                {t('sessions:actions:reset_filters')}
              </Button>
            </div>
          )}
        </div>
        {isEmpty(sessionsByHalfHour)
          ? sessionsLoaded && <EmptySessions entityName="sessions" showArrow={!isFiltered} />
          : map(sessionsByHalfHour, (sessionsHour, hour) => {
              return (
                <div key={hour}>
                  <Tooltip />
                  <div style={{ margin: '25px 0 8px 0', fontSize: 18 }}>
                    {format(new Date(hour), timeFormat)}
                  </div>
                  <>
                    {map(sessionsHour, session => {
                      const isRegistrationEnabled = !isNil(session.registration_limit);

                      return (
                        <SessionContainer key={session.id}>
                          <div style={{ display: 'flex' }}>
                            <DeletableWrapper>
                              <Deletable
                                onDelete={() => onRemove(session.id)}
                                modalHeading={t('sessions:actions:confirm_remove:header')}
                                modalContent={t('sessions:actions:confirm_remove:warning', {
                                  name: session.name
                                })}
                                noButton
                              >
                                <div
                                  {...(isSelectedConfPast
                                    ? { 'data-tip': t('common:errors.past_event') }
                                    : {})
                                  }
                                >
                                  <Button
                                    appearance="danger"
                                    iconBefore={<TrashIcon label="trash-icon" />}
                                    title={t('sessions:actions:confirm_remove:header')}
                                    isDisabled={isSelectedConfPast}
                                  />
                                </div>
                              </Deletable>
                            </DeletableWrapper>
                            <ConflictsPopover
                              isOpen={isPopoverOpen === session.id}
                              setOpen={open => setPopoverOpen(open ? session.id : null)}
                              sessions={sessions}
                              session={session}
                              selectedConf={selectedConf}
                              timeFormat={timeFormat}
                            />
                            <div style={{ fontSize: 16 }}>
                              <div>{session.name}</div>
                              <div style={{ marginTop: '4px', color: 'var(--gray-2)' }}>
                                {format(new Date(session.from), timeFormat)} —{' '}
                                {format(new Date(session.to), timeFormat)}
                              </div>
                              {isRegistrationEnabled && (
                                <div style={{ color: 'var(--gray-2)' }}>
                                  {t('sessions:form:users_number', { number: session.session_registration.length} )}
                                </div>
                              )}
                            </div>
                          </div>
                          <RightBlock>
                            {!isEmpty(session.speakers) && (
                              <AvatarGroup
                                maxCount={3}
                                appearance="stack"
                                data={session.speakers.map(sessionSpeaker => ({
                                  key: sessionSpeaker.id,
                                  name: sessionSpeaker.name,
                                  src: sessionSpeaker.picture,
                                  appearance: 'circle',
                                  size: 'medium',
                                  enableTooltip: true
                                }))}
                                size="medium"
                              />
                            )}
                            <Room>{(session.room || {}).name || ''}</Room>
                            <HoverActions>
                              <div
                                style={{ width: '100%', position: 'relative' }}
                                {...(isSelectedConfPast
                                  ? { 'data-tip': t('common:errors.past_event') }
                                  : {})
                                }
                              >
                                <Button
                                  appearance="subtle"
                                  iconBefore={<EditIcon label="edit-icon" />}
                                  title={t('sessions:actions:edit', { name: session.name })}
                                  isDisabled={isSelectedConfPast}
                                  onClick={() => setSelectedSession(cloneDeep(session))}
                                />
                              </div>
                            </HoverActions>
                          </RightBlock>
                        </SessionContainer>
                      );
                    })}
                  </>
                </div>
              );
            })}
      </Page>
    </>
  );
};

export default compose(
  withRouter,
  ConfSelected
)(ConfProgram);
