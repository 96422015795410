import React, { useState } from 'react';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import { colors } from '@atlaskit/theme';
import ImportExportService from '../../services/ImportExportService';
import { addFlag } from '../../store/actions/flags';
import DataLayer from '../../services/DataLayer';

const XlsxExport = ({ eventId, eventName, entityName, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);

  // TODO replace with selector in the store
  const { user } = useMappedState((state) => state.auth);
  const userEmail = user.profile ? user.profile.email : '';

  const onExport = () => {
    setDownloading(true);

    ImportExportService.export(eventId, entityName)
      .then((data) => {
        fileDownload(data, `${entityName}.xlsx`);

        dispatch(
          addFlag({
            appearance: 'success',
            title: `export:${entityName}:success`,
            timeout: 5000,
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlag({
            description: '',
            appearance: 'error',
            icon: <ErrorIcon label="Error icon" primaryColor={colors.R300} />,
            title: `export:${entityName}:error`
          })
        );
      })
      .finally(() => {
        setDownloading(false);
        DataLayer.emitExportEventData(eventName, entityName, userEmail);
      });
  };

  return (
    <Button
      data-tip={t(`export:${entityName}:to_xlsx`)}
      iconBefore={<DownloadIcon label="download-icon" />}
      style={{ marginRight: 8 }}
      isDisabled={disabled}
      isLoading={downloading}
      onClick={onExport}
    />
  );
};

export default XlsxExport;
