import { isEmpty } from 'lodash/fp';
import HttpService from './HttpService';
import { getZonedISOString } from './DatesService';

class ConfsService extends HttpService {
  entity = 'confs';

  create(payload) {
    return this.http
      .post(
        '/rpc/create_conf',
        { data: payload },
        {
          headers: this.defaultHeaders,
        }
      )
      .then(this.onResponse)
      .catch(this.onError);
  }

  findAll(userId) {
    return this.http
      .get('/conf', {
        params: {
          owner_id: `eq.${userId}`,
          select:
            '*,theme(logo,color,background,font),speakers:speaker(name,picture),conf_limits(*)',
          'speakers.limit': 5,
        },
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  delete(conf) {
    return this.http
      .delete(`/conf`, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${conf.id}`,
        },
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  duplicate(confid, rooms, speakers, links, sessions, sponsors) {
    return this.http
      .post(
        `/rpc/duplicate_conf`,
        { confid, rooms, speakers, apps: links, sessions, sponsors },
        {
          headers: this.defaultHeaders,
        }
      )
      .then(this.onResponse)
      .catch(this.onError);
  }

  update(payload) {
    return this.http
      .patch('/conf', payload, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`,
        },
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  isCreatable(payload) {
    return ['name', 'from', 'to'].every((key) => !isEmpty(payload[key]));
  }

  firstDateCanBeChanged(id) {
    return this.http
      .get('/rpc/conf_first_date_can_be_changed', {
        params: {
          conf: id,
        },
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  lastDateCanBeChanged(id) {
    return this.http
      .get('/rpc/conf_last_date_can_be_changed', {
        params: {
          conf: id,
        },
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  getConfLimits(id) {
    return this.http
      .get('/rpc/get_conf_limits_usage', {
        params: {
          conf: id,
        },
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  getZonedConfData(conf) {
    const timezone = conf.timezone;

    return {
      ...conf,
      from: getZonedISOString(conf.from, timezone),
      to: getZonedISOString(conf.to, timezone)
    };
  }
}

const confsService = new ConfsService();

export default confsService;
