import HttpService from './HttpService';

class SponsorsService extends HttpService {
  entity = 'sponsors';

  create(payload) {
    return this.http
      .post('/sponsor', payload, {
        headers: this.defaultHeaders
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  update(payload) {
    return this.http
      .patch('/sponsor', payload, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  findAllOfConf(confId) {
    return this.http
      .get('/sponsor', {
        params: {
          conf_id: `eq.${confId}`,
          order: 'name.asc'
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  delete(sponsorId) {
    return this.http
      .delete(`/sponsor`, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${sponsorId}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const sponsorsService = new SponsorsService();

export default sponsorsService;
