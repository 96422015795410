import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import QuestionsIcon from '@atlaskit/icon/glyph/questions';
import HipchatSdVideoIcon from '@atlaskit/icon/glyph/hipchat/sd-video'
import MenuIcon from '@atlaskit/icon/glyph/menu';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { getSelectedConf } from '../../store/selectors/confs';
import { setSidebarVisibility } from '../../store/actions/sidebar';
import AppLogo from '../layout/logo';
import NavLinks, { NavItemStyle, NavLinkActiveStyle } from './navlinks';
import Badge from '../layout/badge';
import { findSessions } from '../../store/actions/sessions';
import { getOverlappingSessions, isSessionsOutOfRange } from '../../services/SessionsFiltersService';
import { FAQ_LINK, VIDEO_GUIDE_LINK } from '../../constants';

const SideBarContainer = styled.div`
  flex: 0 0 ${props => (props.isOpen ? '330px' : '0')};
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  @media (max-width: 380px) {
    flex: 0 0 ${props => (props.isOpen ? '100%' : '0')};
  }
`;

const SidebarWrapper = styled.div`
  text-align: center;
  height: 100vh;
  width: ${props => (props.isOpen ? '330px' : '0')};
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(189, 195, 199, 0.12), 0 1px 2px rgba(189, 195, 199, 0.24);
  position: fixed;
  padding-top: 15px;
  z-index: 2;
  @media (max-width: 380px) {
    width: ${props => (props.isOpen ? '100%' : '0')};
  }
`;

const SidebarItem = styled.div`
  margin: 20px 15px 0 50px;
  font-size: 16px;
`;

const SidebarItemLink = styled.div`
  display: flex;
  align-items: baseline;
`;

const IconTitle = styled.div`
  padding-left: 7px;
  font-size: 22px;
  font-weight: 700;
`;

const SessionStatus = styled(Badge)`
  position: relative;
  bottom: 3px;  
`;

const Sidebar = () => {
  const { t } = useTranslation();
  const [overlappingSessions, setOverlappingSessions] = useState(false);
  const dispatch = useDispatch();
  const { sessions, selectedConf, isVerified, sidebarIsShown } = useMappedState(state => ({
    sessions: state.sessions.list,
    selectedConf: getSelectedConf(state),
    // for now we don't verify if the user has a verified email address
    isVerified: true, // isConnectedUserVerified(state)
    sidebarIsShown: state.sidebar.isShown
  }));
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const maybeCloseSidebar = () => {
    if (isTabletOrMobile) {
      dispatch(setSidebarVisibility(false));
    }
  };

  useEffect(() => {
    if (selectedConf) {
      dispatch(findSessions(selectedConf.id));
    }
  }, [dispatch, selectedConf]);

  useEffect(() => {
    if (selectedConf) {
      const overlappingSessions = getOverlappingSessions(sessions);
      const isSessionConflict = !isEmpty(overlappingSessions) || isSessionsOutOfRange(selectedConf.from, selectedConf.to, sessions);

      setOverlappingSessions(isSessionConflict);
    }
  }, [sessions, selectedConf]);

  return (
    <SideBarContainer isOpen={sidebarIsShown}>
      <SidebarWrapper isOpen={sidebarIsShown}>
        <div
          style={{
            position: 'absolute',
            // on small screens, show the burger icon inside the sidebar
            right: isTabletOrMobile && sidebarIsShown ? 20 : -50,
            top: 21,
            cursor: 'pointer'
          }}
          onClick={() => {
            dispatch(setSidebarVisibility(!sidebarIsShown));
          }}
        >
          <MenuIcon size="large" label="burger" />
        </div>
        {sidebarIsShown && (
          <div>
            <AppLogo />
            <div style={{ textAlign: 'left', marginTop: 65 }}>
              <SidebarItem>
                <NavLink
                  to={`/`}
                  exact
                  style={NavItemStyle}
                  activeStyle={NavLinkActiveStyle}
                  onClick={maybeCloseSidebar}
                >
                  <SidebarItemLink>
                    <FeedbackIcon label="home-icon" />
                    <IconTitle>{t('sidebar:confs-list')}</IconTitle>
                  </SidebarItemLink>
                </NavLink>
              </SidebarItem>
              <SidebarItem>
                {/* eslint-disable-next-line*/}
                <a href={FAQ_LINK} target="_blank" style={NavItemStyle}>
                  <SidebarItemLink>
                    <QuestionsIcon />
                    <IconTitle>{t('sidebar:how_to')}</IconTitle>
                  </SidebarItemLink>
                </a>
              </SidebarItem>
              <SidebarItem>
                {/* eslint-disable-next-line*/}
                <a href={VIDEO_GUIDE_LINK} target="_blank" style={NavItemStyle}>
                  <SidebarItemLink>
                    <HipchatSdVideoIcon />
                    <IconTitle>{t('sidebar:video_guide')}</IconTitle>
                  </SidebarItemLink>
                </a>
              </SidebarItem>
              {selectedConf && (
                <>
                  <SidebarItem onClick={maybeCloseSidebar}>
                    <SidebarItemLink style={NavItemStyle}>
                      <CalendarIcon label="conf" />
                      <IconTitle>
                        {selectedConf.name} <SessionStatus>{t(`conf_plans:${selectedConf.conf_limits.current_plan}`)}</SessionStatus>
                      </IconTitle>
                    </SidebarItemLink>
                  </SidebarItem>
                  <NavLinks
                    t={t}
                    isVerified={isVerified}
                    selectedConf={selectedConf}
                    isProgramWarning={overlappingSessions}
                    onLinkClick={() => maybeCloseSidebar()}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </SidebarWrapper>
    </SideBarContainer>
  );
};

export default withRouter(Sidebar);
